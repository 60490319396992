import React from "react";
import { Helmet } from "react-helmet";
// import Marquee from "../components/Marquee";

export default function approach() {
  return (
    <>
      <Helmet>
        <title>Our Approach at Ratio Lighting</title>
        <meta property="og:title" content="Our Approach" key="ogtitle" />
        <meta
          property="og:description"
          content="We make lighting tools that allow users to create specific lighting effects for harmonious and inviting spatial experiences."
          key="ogdesc"
        />
        <meta
          name="description"
          content="We make lighting tools that allow users to create specific lighting effects for harmonious and inviting spatial experiences."
        />
      </Helmet>
      <main id="main">
        {/* <SubHeaderGeneric productName={tempProductName} /> */}
        <div className="grid-content-footer px-medium pt-small pt-mm-medium fs-mm-xl">
          <article className="grid-child-content pb-large pb-mm-xl max-width-800">
            <h1 className="c-em mb-small">Approach</h1>
            <p>
              We make lighting tools that allow users to create specific
              lighting effects for harmonious and inviting spatial experiences.
            </p>
            <p>
              Working in close collaboration with our clients is a fundamental
              part of our approach. Sometimes we’re there to offer sound,
              practical advice on buildability, whilst at other times we’re a
              soundboard for workshopping and iterating schematic concepts.
              Maintaining close communication and project involvement means
              getting the small details right, which inevitably are the ones
              that make the biggest difference.
            </p>
            <h2 className="c-em mt-large mt-mm-large mb-small">
              Lighting Design
            </h2>
            <p>
              We offer conceptual and technical advice on product selection and
              placement, balancing lighting best practice with design intent and
              site specific requirements. Schematic design is available for
              select projects on request.
            </p>
            <h2 className="c-em mt-large mt-mm-large mb-small">Service</h2>
            <p>
              We believe that attention to detail goes further than product
              design. Our objective is to deliver a thoughtful and dependable
              experience that trickles down from product selection through to
              documentation, with the kind of detail thrown in that leaves a few
              welcome surprises.
            </p>
            <p>
              Get in touch and hear from us pronto.
              <br />
              <a href="mailto:general@ratio.lighting">general@ratio.lighting</a>
            </p>
          </article>
          {/* <div className="grid-child-footer">
            <hr />
            <span>Hello</span>
          </div> */}
        </div>
      </main>
    </>
  );
}
